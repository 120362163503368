body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{
  height: 100%;
}
body{
  line-height: 1;
  font-size: 21px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  background-color: #FFFCF6;
  /* height: 100vh */
}
/* header */
header {
  /* position: fixed;
  top: 0px; */
  
  /* margin-bottom: 2em; */


  /* width: 100%;
  height: 80px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  z-index: 10; */
    display: flex;
    padding: 30px 50px;
    align-items: center;
    background-color: #FFFCF6;
}        

.bottom_line{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

main{
  margin-left: auto;
  margin-right: auto;

}



footer{
  max-width: 1260px;
  min-height: 80px;
  margin: 0 auto;
  padding: 8rem 0 4rem;
  background-color: #FFFCF6;
}



/* logo     */
#logo {
  /* position: absolute;
  top: 21px;
  left: 21px; */
  height: 32px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* nav bar */
.navigation-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}

/* Hamburger */
.hamburger{
  display: none;
}


nav {
  width: 100%;
  z-index: 1001;
  background-color:white;
  display: block;
  position: absolute;
  text-align: center;
  overflow: visible;
  min-width: 200px;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.04);

}

  
nav #menu {
  
  position: relative;
}

nav ul{
  list-style-type: none;
  padding: 0;
  margin:0
}

nav #menu li {
  /* display: inline-block; */
  cursor: pointer;
  line-height: 4.375rem;
  position: relative;
  transition: all 0.3s ease-out;
  list-style-type: none;
  font-size: 1rem;
  text-decoration: none!important;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: uppercase;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  letter-spacing: 0.04em;

}

nav #menu li a {
  text-decoration: none!important;
}

nav #menu li:hover {
  background: #fafafa;
}
  



.nav_link, .contentnav_link, .text_link, .btn-link, .project_pagination_link{
  color: #777777!important;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  line-height: 1.58;
  text-decoration: none!important;
}




.active_link {
  color: #9D2721 !important;
}


.nav_link  {
  text-align: center;
  padding: 9px 20px;
  font-size: 1rem;
  text-decoration: none!important;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'proxima-nova';
  font-weight: 400;
  text-transform: uppercase;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  letter-spacing: 0.04em;
}
.text_link {
  color: #9D2721 !important;
  /* text-decoration: underline!important; */
}
.card_link{
  text-decoration: none!important;
}


.contentnav_link:hover{
  opacity: 0.7;
}


.nav_link:hover {
  opacity: 0.7;
} 


.btn-link:hover {
  opacity: 0.7;
}

.text_link:hover {
  opacity: 0.7;
}


.nav-li{
  /* padding: 5px 0; */
  list-style-type: none;
}

.uk-active{
  position: relative;
}
.scrollby>ul>.uk-active>a:before{
  
    content: "";
    position: absolute;
    top: 11px;
    left: -25px;
    width: 15px;
    border-top: 1px solid #9D2721;
}

.topnav .icon {
  display: none;
}
#resume{
  height: 100%;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

#resumeBtn{
  height: 20px;
  margin-top: 0!important;
}


/* leading */
.leading {
  display: block;
  margin: 64px auto 0;
  max-width: 1260px;
  /* height: 15rem; */
  /* padding: 0 1.5rem 0 1.5rem; */

  /* padding: 30px; */
  background-size: cover;
  padding-top: 0;
  
}

.image_model_link_container{
  text-align: center;
  margin: 10px 0 10px;
}

/* New panel design */
.featureWorkerContainer{
  margin-top: 72px;
}
.work_wrapper {
  /* display: grid; */
  /* grid-template-columns: repeat(10, 1fr); */
  /* grid-gap: 30px; */
  /* max-width: 1260px; */
  /* margin: 0 auto 30px; */
  /* margin-top: 64px;
  margin-bottom: 64px;  */
  /* transition: opacity 1s ease-in-out; */
  /* grid-column-gap: 32px;
  grid-row-gap: 24px; */
  padding-top: 0;

} 

.section-title-container{
  max-width: 1260px;
  margin: 96px auto 64px;
}

.workCards_wrapper{
  max-width: 1260px;
  margin: 0 auto 0;
  padding: 64px 30px 64px;


  /* grid-column-start: 3; */
  /* grid-column-end: 9; */
}


.workCard{
  width: 100%;
  /* margin-bottom: 64px; */
  /* width: 1px; */
  height: 100%;
  position: relative;
  /* z-index: 999; */
  transform: translateZ(100px);
  transition: all 0.3s ease 0s;
} 

.subCards_container{
  margin-top: 40px;
}

.workCard_text_container_sub{
  margin-top: 24px;
}


.card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-top: 0px!important;
  
}



.card-img:hover{
  transform: scale(0.98);
  transition: transform 275ms cubic-bezier(.39, .575, .565, 1);
}

.card_name {
  font-family: proxima-nova, sans-serif; 
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 11.52px;
  margin-bottom: 11.52px;
  

}

.card_tagline{
  font-size: 21px;
  line-height: 1.58;
  /* margin: 1.95em 0 -0.46em; */
  color: rgba(0, 0, 0, 0.8);
  font-family: proxima-nova, sans-serif; 
  margin-top: 11.52px;
  margin-bottom: 11.52px;
}

.card_description{
  font-size: 16px;
  /* margin-bottom: 21px; */
  font-weight: 300;
  opacity: 0.54;
  font-family: ff-cocon-pro, sans-serif;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.card_hover{
  opacity: 1;
  transition: opacity .5s ease-in-out;
}


.card_hover:hover{
  opacity: 0.7
}
.sub_card_tagline{
  font-size: 14px;
  /* margin-bottom: 21px; */
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  font-family: ff-cocon-pro, sans-serif;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.sub_card_name{
  font-family: proxima-nova, sans-serif; 
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: -6px;

}

.sub_card_description{
  font-size: 21px;
  line-height: 1.58;

  color: rgba(0, 0, 0, 0.8);
  font-family: proxima-nova, sans-serif; 

}



.uk-label{
  color:rgba(157,39,33);
  background-color: rgba(157,39,33,0.2);
  /* border: solid 1px black; */
  opacity: 0.54;
  border-radius: 0;
  padding: 2px 12px 2px 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-feature-settings: "smcp";
  font-variant: small-caps;


}
/* card grids for portfolio*/
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-gap: 30px;
  max-width: 1260px;
  margin: 0 auto 30px;
  margin-top: 20px;
  transition: opacity 1s ease-in-out;
  padding-top: 0;

} 



article {
  position: relative;
}
.cardcontainer {
  position: relative;
}

.article-img {
  height: 370px;
  width: 100%;
  object-fit: cover;
  margin-top: 0px!important;
}

  /* card title */
.article-title {
  color: white;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: normal;
  font-weight: 600;
  position: relative;
  top: 50%;
  left: 50%;
  padding: 0 40px;
  transform: translate(-50%, -50%);
  /* text-align: center; */
}

  /* card subtitle */
.article-subtitle {

  font-size: 18px;
  line-height: 24px;
  margin: 10px 0px;
  font-weight: 300;
  line-height: 1.58;
}

.code {
  position: absolute;
  bottom: 21px;
  left: 21px;
  height: 20px;
  opacity: 0;
  transition: .5s ease-in-out;
  z-index: 5;
}

.invert {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease-in-out;
  z-index:2;
}

.tbdOverlay{
    color: white;
    margin: 0;
    transform: translate(-50%,-50%);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
}

.shade {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
}


#imgGoogleBook {
  background-color: #F8E4B0;
}
/* #img2 {
  background-color: #270A74;
} */
#imgGifTastic {
  background-color: #964B54;
}
/* #img4 {
  background-color: #E2AB09;
} */
#imgGiphy {
  background-color: #282C3A;
}
/* #img6 {
  background-color: #F8E4B0;
} */
/* #img7 {
  background-color: #00AE62
} */
#imgBamazon {
  background-color: #FFC97F
}
#imgFriendFinder {
  background-color: #FFECB3
}
/* #img10 {
  background-color: #FFCDD3
} */
#imgPetPark {
  background-color: #9FB46F
}
#imgNews{
  background-color: #70889C
}
#imgWIS{
  background-color: #202020
}

#imgNightkick{
  background-color: #213060;
}

#imgReadwithMe{
  background-color: #EEF1F3
}

#imgMB{
  background-color: #FDF6F3
}

#imgSW{
  background-color: #E2FAF7
}

#imgPhotome{
  background-color: #FFF4F5
}

#imgWeather{
  background-color: #CBD6E5
}

#imgLightup{
  background-color: #394552
}

#imgBoardingPass{
  background-color: #6D98E8
}

#imgIoT{
  background-color: #848484
}

#imgKit{
  background-color: #F1EFED
}

#imgTeacher{
  background-color: #77B6FF;
}
#imgMovie{
  background-color: white;
}

#imggraphic{
  background-color: #2E100B;
}

#imgUplift{
  background-color: #92A9FF;
}

.cardcontainer:hover .overlay{
  opacity: 1;
}

article:hover .code {
  opacity: 1;
}

/* social media icon */
.fa {
  margin-top: 15px;
  padding: 10px;
  height: 50px;
  transition: opacity .5s ease-in-out;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* carousel for play */

.play_container {
  max-width: 1260px;
  margin: 0 auto 30px;
  margin-top: 20px;
}

/* text for about */

.about_wrapper {
  max-width: 1260px;
  margin: 0 auto 30px;
  /* margin-top: 20px; */
  padding-top: 0;
} 

.textcontainer {
  color: #4A4A4A;
  font-size: 17pt;
  line-height: 26pt;
  margin-top: 60px;
}



/* typographic system */
/* project title */
h1{

  font-family: ff-cocon-pro, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  color: rgba(0, 0, 0, 0.8)

}


b{
  font-weight: 700
}
/* project subtitle */
h3{
  font-size: 24px;
  margin: 16px 0 16px;
  line-height: 1.58;
  letter-spacing: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-family: proxima-nova, sans-serif;
  

}



/* nothing */
h4{
  font-size: 18px;
  line-height: 24px;
  margin: 10px 0 -32px;
  font-weight: 400;
  line-height: 1.58;
  font-family: proxima-nova, sans-serif;
  /* font-variant: small-caps; */
  text-transform: uppercase;
  letter-spacing: 0.12em;


}

/* body section name in red */
h2{
  font-size: 16px;
  letter-spacing: 0.12em;
  margin: 0 1px 18px;
  font-family: ff-cocon-pro, sans-serif;
  color: #9D2721;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-transform: uppercase;
  /* letter-spacing: 1.5px; */
  font-weight: 300;
}

/* body section subtitle */
h5{
  font-size: 32px;
  line-height: 1.32;
  margin: 0 0 36px;
  font-weight: 600;
  font-family: proxima-nova, sans-serif;

}

/* sub sub subtitle */
h6{
  font-size: 24px;
  line-height: 1.58;
  font-weight: 600;
  margin: 2.95em 0 -0.46em;

  font-family: proxima-nova, sans-serif;
  /* background-color: white; */

}



p, .p{
  font-size: 21px;
  line-height: 1.58;
  /* margin: 1.95em 0 -0.46em; */
  color: rgba(0, 0, 0, 0.8);
  font-family: proxima-nova, sans-serif;

}

.p_small{
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-family: proxima-nova, sans-serif;
  line-height: 18px;
  margin-top: 4px;
}



li, ol{
  font-size: 21px;
  list-style-position: inside;
  line-height: 1.58;
  list-style-type: circle;
  font-family: proxima-nova, sans-serif;
}

li:not(.project_brief_content){
  margin: 1.05em 0 -0.46em;
}

/* bulletin title */
.p-list{
  margin-top: 2.25em;
  /* margin-bottom: -1.25em; */
  font-weight: 500;
  color: black;
  letter-spacing: 0.04em;  
}

.footer_content{
  font-size: 18px;
  margin: 60px 0px;
  padding: 0 1.5rem 0 1.5rem;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 300
}

.footer_content p{
  color: rgba(0, 0, 0, 0.54)!important;
  font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1rem;
}

.footer_link{
  margin-top: 1em
}

.footer_content .fab, .footer_content .fas{
  margin-right: 21px;
  font-size: 18pt;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.54)!important
}





.project_info{
  font-size: 18px;
  /* margin-bottom: 21px; */
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-family: proxima-nova, sans-serif;
}


.project_bracket_title{
  color: rgba(0, 0, 0, 0.54);
  font-size: 21px;
  line-height: 1.58;
  font-weight: 300;
  margin: 2.95em 0 -2.95em;
}

.project_caption{
  /* margin-top: 21px;
  margin-bottom: 2em; */
  display: block;
  padding: 24px 0 0;
  font-size: 18px;
  line-height: 1.4;;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-family: proxima-nova, sans-serif;

}

.project_brief_title{
  font-size: 16px;
  /* margin-bottom: 21px; */
  font-weight: 500;
  /* color: rgba(0, 0, 0, 0.54) */
  /* padding: 16px 0 2px; */
  line-height: 21.6px;
  /* font-variant: small-caps; */
  font-family: proxima-nova-condensed, sans-serif;



}

.project_brief_content{
  font-size: 16px;
  /* margin-bottom: 21px; */
  font-weight: 400;
  line-height: 1.58;
  color: rgba(0, 0, 0, 0.54);
  font-family: proxima-nova-condensed, sans-serif;

}



.divider{
  height:4rem;
}

.end-nav-divider{
  margin-top: 4em;
}

.end-nav-title{
  margin-bottom: 1em;
}
.end-nav-container{
  margin: 2em 0 2em 0
}


/* project article container */
.project_overall_container{
  max-width: 1260px;
  margin: 0 auto 30px;
  /* padding: 0 1.5rem 0 1.5rem; */
}
.project_head_container{
  max-width: 1260px;
  margin: 64px auto 30px;
  /* padding: 0 1.5rem 0 1.5rem; */
}

.left_bar_wrapper{
  max-width: 100%;
  margin: 6em auto 35px;
  padding-right: 54px
}

.project_brief_container{
  padding-bottom: 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.uk-sticky-below{
  padding: 0 0 1em
}

.scrollby{
  /* padding-top: 28px; */
  position: -webkit-sticky!important;
  position: sticky!important; 
  top: 100px!important; 
  z-index: 980;
  /* width: 200px; */
}

.project_container{
  max-width: 100%;
  margin: 0 auto 35px;
}

.project_head{
  /* margin-top: 6em; */

}

.project_overview_container{
    max-width: 1260px;
    margin: 64px auto 30px;
    /* padding: 0 1.5rem 0 1.5rem; */
    
    
}


.project_overview_title{
  font-size: 21px;
  line-height: 1.58;
  font-weight: 600;
  font-family: proxima-nova, sans-serif;
  margin: 21.6px 0 10px;
}

.project_overview_content{
  font-size: 18px;
  line-height: 1.58;
  /* margin: 1.95em 0 -0.46em; */
  color: rgba(0, 0, 0, 0.54);
  font-family: proxima-nova, sans-serif;
}

.project_headerImg{
  margin-top: 2em;
  width: 100%;
  object-fit: cover
}

.project_body{
  margin-top: 8em;
}

.project_separator {
  margin-top: 60px;
  background-color: #FFF7EF;
  width: 100%;
  position: relative;
  z-index: 998;
  border-radius: 24px;
}

.project_separator_transparent {
  margin-top: -36px;
  width: 100%;
  position: relative;
}

.project_separator_inner_wrapper {
  padding: 60px 60px;
}

.project_separator_inner_wrapper_small{
  max-width: 1000px;
  margin: 0 auto 0;
  padding: 60px 60px;
} 
.project_separator_title{
  color: #9D2721;
  font-size: 21px;
  line-height: 1.58;
  font-weight: 600;
  font-family: proxima-nova, sans-serif;
}

.project_quote {
  margin: 100px 0 100px;
  border-left: solid #f8f8f8;
  width: 100%;
  position: relative;
}

.project_quote_text {
  font-size: 30px;
  line-height: 44px;
  font-style: italic;
}

.project_quote_wrapper{
  padding: 0 0 0 30px;
}

/* small quote */
.project_quote_sm {
  margin: 8px 0;
  padding: 8px 0;
  border-left: solid 1px #dcdcdc;
  width: 100%;
  position: relative;
}

.project_quote_sm_wrapper{
  padding: 0 0 0 16px;
}



.project_quote_sm_text{
  font-size: 16px;
  line-height: 120%;
  font-style: italic;
  color: rgba(0,0,0,0.54);
}



.reflect_box{
  padding: 50px 50px;
  border-left: 6px solid rgba(153, 199, 255, 1);
  background-color: rgba(153, 199, 255, 0.2)
}

/* illustrations */
/* img{
  margin-top: 56px;
} */

.frame_wrapper{
  margin: 56px 0 56px;
}

#me{
  width: 50%;
}

.project_illustration{
  margin-top: 30px;
  /* margin-top: 2em; */
  /* margin-bottom: 2em; */
  width: 100%;
  object-fit: cover
}


.project_illustration_medium{
  margin-top: 30px;
  /* margin-top: 2em; */
  /* margin-bottom: 2em; */
  width: 80%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.project_illustration_small{
  margin-top: 30px;
  /* margin-top: 2em; */
  /* margin-bottom: 2em; */
  width: 60%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.project_illustration_extrasmall{
  margin-top: 30px;
  /* margin-top: 2em;
  margin-bottom: 2em; */
  width: 25%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.project_illustration_nomargin{
  
  margin-top: 16px;
}

.project_square_illustration_container{
  width: 100%;
  height: 200px
}

.project_square_illustration{

  height: 100%;
  object-fit: cover;
  width: 100%;

  box-sizing: border-box;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  transition: .1s ease-in-out;
  transition-property: color,background-color,border-color;
}



.project_model_wrapper{
  margin: 0 auto 0

}


.project_image_model_portrait{
  height: 100%;
  max-width: 90vw;
  display: block;
  margin: 0 auto 0;
  max-height: 90vh;
  /* object-fit: cover */
}

.project_image_model{
  width: 100%;
  max-width: 90vw;
  display: block;
  margin: 0 auto 0;
  max-height: 90vh;
  /* object-fit: cover */
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: 100%;
 
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  /* margin-bottom: 30px; */
}

.masonry-image{
  margin: 15px 15px 15px 15px;

}
/* set the cursor */
.pointer {cursor: pointer;}

/* Media Query */

/* default extra large: HD destop 1200px and up*/

/* hide the scroll by */

.about_content, .footer_content, .leading,  .project_head_container, .project_overall_container, .section-title-container, .project_overview_container{
  padding-left: 30px;
  padding-right: 30px;
}

/* back to top */
.scroll{
  opacity: .6;
}

/* Pagination */
.pagination{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project_pagination_wrapper{
  margin-top: 4em;
  padding: 4em 0 0;
  /* background-color: #f8f8f8; */
}
.project_pagination{
  max-width: 1260px;
  margin: 0 auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center
}

.project_pagination_item{
  display: flex;
  flex-direction: row;
  align-content: center;
  color: #777777!important;
  text-decoration: none!important;
}

.project_pagination_link{
  line-height: 1;
}


.project_pagination_link::after{
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 4px;
  background-color: #777777;
  opacity: 0.8;
  visibility: hidden;
  transform-origin: left;
  transform: scale(0);
  transition: 0.25s ease-out;
}


.project_pagination_link:hover::after{
  transform: scale(1);
  visibility: visible;
}




/* hidden content */
.hiden_content_switch_container{
  padding: 32px 0 32px
}

.uk-button{
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.btn_light{
  background-color: #FFFCF6;
}

.circle_number{
  background-color: #9D2721;
  color: white;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  margin-right: 8px;
}


.unset-Sticky{
  position: relative!important;
}


/* large: desktop 1040 and up*/
@media (max-width: 1200px) {
/* 
  .leading-bigtext {
      font-size: 5.25rem;
  }
  .leading-text {
      font-size: 3.5rem;
  } */
  .p-list{
    margin-top: 2em;
  }

}

/* for better alignment to the logo*/
@media (max-width: 1090px) {
  /* .about_content, .project_container, .footer_content{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  } */
  
}


/* medium: tabloid 768 and up*/
@media (max-width: 992px){

  .divider{
    height: 1rem;
  }
  /* .leading-bigtext {
      font-size: 4rem;
  }
  .leading-text {
      font-size: 2.66666667rem;
  }
  #logo{
    height: 2.5rem
  } */
  /* body */
  /* h2{
    margin-top: 2rem;
  } */
  .p-list{
    margin-top: 21px;
  }

  .project_illustration{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .project_illustration_medium{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .project_illustration_small{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .left_bar_wrapper{
    padding-right: 0
  }
  .scrollby{
    display: none
  }
  .left_bar_wrapper,.project_body{
    margin-top: 3rem;
  }

  .project_separator_inner_wrapper {
    padding: 80px 30px 120px;
  }
  

}

/* small: landscape phones, 576 and up*/
@media (max-width: 768px){
  .divider{
    height: 10px;
  }
  /* .leading-bigtext {
    font-size: 3rem;
}
  .leading-text{
  font-size: 2rem;
  } */
  /* body */
  br{
    display: none;
  }


.hamburger{
  display: block;
}

.nav_link_container{
  display: none;
}

}


/* extra small: portrait phones, less than 576*/
@media(max-width: 576px){
 
  /* .leading-bigtext {
    font-size: 2.5rem;
  }
  .leading-text{
      font-size: 1.666667rem;
  } */
  .divider{
    height:10px;
  }

  li, ol, p{
    font-size: 17px;
    line-height: 1.58;
  }

  .project_caption, .project_info{
   
    font-size: 14px;
  
  }
  
  /* #logo{
    height: 2rem
  } */
  header, .about_content, .footer_content, .leading,  .project_head_container, .project_overall_container, .section-title-container, .project_overview_container{
    padding-left: 16px;
    padding-right: 16px;
  }

  #logo{
    left: 16px
  }
  
}


.scroll {
    display: none;
    opacity: 1;
    width: 2.5rem;
    height: 2.5rem;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    /* border-color: #9D2721;
    color:#9D2721; */
    background-color: white
}

.scroll:hover {
    background-color: #9D2721;
    border-color: #9D2721;

}
.content{
    position: fixed;
    bottom: 3.5rem;
    right: 0.8rem;
    z-index: 9999;
    min-width: 280px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1.8em;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.10);
    border-radius:4px;
    font-size: 0.9em;
    background-color: #fff;
}

.content ul{
    margin:0;
    padding:0;
}

.arrow-up {
    margin-right: 0!important;
    margin-left: 0!important;
    margin-top: 0rem!important;
    margin-left: 0rem!important;
}


@media (max-width: 768px) {
    
    .scroll {
        display: block;
        /* width: 2rem;
        height: 2rem; */
        bottom: 1.5rem;
        right: 0.8rem;
        padding-left: 6px;
        padding-right: 6px;

    }
  
}
